body { 
	font-size: 14px; 
}
.form-check-input[type=checkbox] {
	margin-top: 2px;
}
.loading-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  flex-direction: column;
  text-align: center;
  padding: 20px;
}
.loading-container .spinner-border {
	border-color: #fff #fff #fff transparent;
	margin-bottom: 10px;
}
.loading-text {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 5px;
  animation: fadeIn 1.5s infinite alternate;
}
.table-responsive { 
	width: 100%;
	margin-bottom: 1rem;
}
.btn { 
	font-size: 14px; 
}
.link {
	color: #2D831F;
	cursor: pointer;
	text-decoration: none;
	display: inline-block;
}
table { 
	margin-bottom: 0px !important;
}
thead, 
tbody { 
	border-width: 0px !important; 
}
table input, 
table .btn { 
	margin-top: 0; 
}
th { 
	font-weight: 500;
	background-color: #f4f4f4 !important;
}
th,
td {
	vertical-align: bottom;
	padding: 0.5rem !important;
	border-bottom: #e4e4e4 solid 1px !important;
}
.table-borderless th, 
.table-borderless td {
	border-bottom: none !important;
}
table.no-wrap th, 
table.no-wrap td {
	white-space: nowrap;
}
.table.bg-border-none th, 
.table.bg-border-none td {
	border: none !important;
	background: transparent !important;
}
.accordion-button:not(.collapsed) {
	color: #2D831F;
	background-color: #2D831F17;
}
.accordion-button:focus {
	box-shadow: 0 0 0 0.25rem #2D831F2E;
}
input[type="number"] {
	min-width: 65px;
	padding-right: 5px;
}
input[type="text"] {
	min-width: 66px;
}
input[type="date"] {
	width: 115px;
	font-size: 14px;
	padding: 8px 2px;
	letter-spacing: 0px;
}
td .btn { 
	white-space: nowrap; 
}
.App td .btn-primary {
	color: #fff;
	font-weight: 500;
	padding: 5px 10px;
	background: #2D831F;
	border-radius: 5px !important;
}
.App td .btn-primary:hover {
  color: #fff;
  border-color: #333;
  background-color: #333;
}
.form-select {
	min-width: 100px;
	padding: 0.375rem 2.0rem 0.375rem 0.75rem;
}
.btn-group, 
.btn-group-vertical {
	align-items: center;
}
.table > :not(:first-child) {
  border-top: none;
}
.btn-check:focus + .btn-primary, 
.btn-primary:focus {
	box-shadow: none;
	background: green;
	border-color: unset;
}
.btn-check:focus + .btn-primary, 
.btn-primary, .btn-primary.disabled, 
.btn-primary:disabled {
	border-color: green;
	background-color: green;
}
.btn-check:focus + .btn-primary, 
.btn-primary:hover {
	border-color: rgb(1, 94, 1);
	background-color: rgb(1, 94, 1);
}
.wrapper {
	display: flex;
	min-height: 100vh;
	position: relative;
}
.wrapper:after {
	content: '';
	width: 100%;
	height: 100%;
	display: none;
	position: absolute;
	background-color: rgba(0,0,0,0.3);
}
.open.wrapper:after {
	display: block;
}
.asideLeft {
	z-index: 99;
	width: 250px;
	left: -250px; 
	height: 100%; 
	position: absolute; 
	background: #2D831F;
	transition: ease-out all 0.3s;
}
.open .asideLeft {
	left: 0px;
	position: absolute;
}
.asideLeft .title {
	color: #fff;
	background-color: #6DAE19;
}
.asideLeft ul {
	padding-left: 0;
	margin-bottom: 0px;
	list-style-type: none;
}
.asideLeft li {	
	position: relative;
}
.asideLeft .side-navigation-panel-select-option  {
	color: #fff;
  display: flex;
	font-size: 18px;
	cursor: pointer;
  padding: 15px 20px;
  align-items: center;
  transition: ease-out all 0.3s;
  border-bottom: #5A9312 solid 1px;
}
.asideLeft .side-navigation-panel-select-option i { 
	margin-right: 10px;
}
.asideLeft .side-navigation-panel-select-option:hover  { 
	background-color: #5A9312;
}
.asideLeft .side-navigation-panel-select-option-icon { 
	margin-left: auto;
}
.side-navigation-panel-select-inner-option {
  color: #fff;
  cursor: pointer;
  background: #343434;
  padding: 10px 10px 10px 55px;
  transition: ease-out all 0.3s;
  border-bottom: #565656 solid 1px;
}
.side-navigation-panel-select-inner-option:hover {
	background: #565656;
}
.company {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #6DAE19;
}
.asideLeft .company h2 {
	color: #fff;
	margin-bottom: 0;
	padding: 10px 10px;
	text-shadow: 2px 2px 4px rgba(0,0,0,0.3); 
}
.asideRight {
	width: 100%;
	padding: 0 30px;
}
.main-header { 
	height: 80px; 
	display: flex;
	padding: 10px 15px;
	position: relative;
	align-items: center;
	margin: 0 -30px 15px;
	border-bottom: #eaeaea solid 1px; 
}
.main-header h2 { 
	padding: 0 15px; 
	margin-bottom: 0; 
}
.main-header .username .btn {
	color: #6DAE19;
	border-color: #e4e4e4;
}
.main-header .dropdown-menu { 
	min-width: auto; 
}
.toggle-button.btn {
	left: 0; 
	width: 56px;
	color: #666;
	z-index: 999;
	height: 40px;
	display: flex;
	display: block;
	overflow: hidden;
	background: none;
	padding: 7px 15px;
	border-radius: 4px; 
	position: relative; 
	border-color: #eaeaea;
	transition: ease-out all 0.3s;
}
.toggle-button.btn i { 
	transition: ease-out all 0.3s; 
}
.toggle-button.btn i:first-child {
	margin-right: 15px;
	margin-left: -40px;
}
.open .toggle-button.btn i:last-child {
	margin-right: -40px;
}
.open .toggle-button.btn i:first-child {
	margin-left: 0px;
	margin-right: 15px;
}
.nav-cards h1 { 
	line-height: 0.8;
	font-weight: bold;
}
.nav-cards .card {
	border: none;
	padding: 5px 0px;
	border-radius: 10px;
	margin-bottom: 15px;
	border: #f2f1f1 solid 1px;
	/*box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);*/
	background: linear-gradient(180deg, #F6F6F6 0%, #FFFFFF 100%);
}
.nav-cards .icon {
	width: auto;
	height: 50px;
	margin-left: auto;
	filter: brightness(1) contrast(0);
}
.open .toggle-button { 
	color: #fff;
	left: 235px; 
	background: #333; 
	border-color: #333; 
	border-radius: 0 4px 4px 0; 
}
.nav-tabs { 
	/*display: flex;
	overflow-x: auto;
	overflow-y: clip;
	flex-flow: nowrap;
	white-space: nowrap;*/
	border-color: #e9e9e9;
}
.nav-tabs .nav-link {
	color: #000;
	border-width: 1px;
	border-style: solid;
	background: #f6f6f6;
	border-color: #e9e9e9;
	border-radius: 10px 10px 0 0;
}
.tab-content > .active {
  border-style: solid;
  border-color: #e9e9e9;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 10px 10px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}
.admin-tabs .btn { 
	margin: 0 10px 10px 0;
}
.sub-header { 
	display: flex; 
	flex-flow: wrap;
	margin: 0 -15px;
	padding: 0px 15px;
	justify-content: flex-end; 
	border-bottom: #eaeaea solid 1px; 
}
.sub-header h2 { 
	font-size: 24px;
	margin-right: auto;
}
.sub-header h2 span { 
	color: #999; 
	font-size: 16px; 
}
.sub-header .btn { 
	border-radius: 4px 4px 0 0;
}
.processed-label {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
}

.processed-label::before {
  content: '';
  width: 10px;
  height: 10px;
  background: #00000029; /*#92b58c;*/
}

.processed-order{
	background: #7b7f7b29; /*#2d831f29;*/
}
/*.sub-header a { 
	margin-top: 15px; 
}*/
.delete { 
	color: #f00;
	border: none;
	background: transparent;
}
.delete:hover { 
	background: transparent;
}
.info { 
	border: none; 
	color: #2D831F; 
	background: transparent; 
}
.info .title { 
	color: #000; 
	font-weight: normal; 
}
.info:hover { 
	background: transparent; 
}
.actions span { 
	width: 54px; 
	text-align: center; 
}
.actions span.tag { 
	width: auto; 
	font-size: 12px; 
	padding: 3px 5px; 
	font-weight: 500; 
	margin-right: 5px; 
	background: #e4e4e4; 
}
.actions .btn {
	padding: 0px;
	border: none;
	background: none;
}
.actions .btn:hover { 
	background: none; 
}
.actions .btn.view { 
	color: #2D831F; 
}
.w-54 { 
	width: 54px; 
}
.tab-pane > .add { 
	margin-top: -56px; 
	margin-bottom: 20px; 
}
.tab-pane > .add .btn { 
	border-radius: 5px 5px 0 0; 
}
.form-title { 
	color: #5A9312; 
	font-size: 16px; 
	margin-bottom: 20px; 
	padding-bottom: 10px;
	border-bottom: #5A9312 solid 1px; 
}
.form-group { 
	position: relative; 
	margin-bottom: 15px; 
}
.form-label { 
	font-size: 14px; 
}
.form-group .form-label { 
	left: 5px;
	top: -11px;
	font-size: 12px;
	padding: 0px 4px;
	background: #fff;
	margin-bottom: 0; 
	position: absolute;
}
.check-list .list-group-item {
	border: none;
	padding: 0.2rem 0;
}
.large-grid .btn { 
	font-size: 12px; 
	padding: 0px 5px; 
}
.large-grid th, 
.large-grid td { 
	padding: 0.25rem 0.5rem; 
}
/*.large-grid td:last-child { 
	text-align: right; 
}*/
.css-42igfv {
	margin: auto;
	color: #2D831F;
}
.table-search { 
	display: flex; 
	margin-bottom: 1rem 
}
.table-search label { 
	margin-bottom: 0; 
}
.pagination {
	display: flex;
	align-items: center;
}
.pagination .btn-group { 
	margin-left: auto; 
}
.pagination .btn {
	color: #333;
	padding: 7px 15px;
	border-color: #e4e4e4;
	background: transparent;
}
.pagination .btn.active {
	color: #fff;
	border-color: #2D831F;
	background-color: #2D831F;
}
.data-loading {
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	position: fixed;
	background-color: #E7F4E5 !important;
}
.data-loading img {
	height: 30px;
}
.procatlist th, 
.procatlist td {
	vertical-align: bottom;
	padding: 5px !important;
}
.procatlist td input, 
.procatlist th input {
  padding: 2px;
  font-size: 11px;
  line-height: initial;
}
.procatlist th .form-select {
  font-size: 11px;
  padding: 2px 15px 2px 5px;
  background-size: 9px 12px;
  background-position: right 3px center;
}
.procatlist td, .procatlist td input {
  font-size: 11px;
  line-height: initial;
}
.procatlist table .form-label {
  font-size: 12px;
  font-weight: 500;
  line-height: initial;
}
.procatlist th  input[type="date"], 
.procatlist td  input[type="date"] {
	width: 84px;
}
.procatlist td .btn-primary{
	font-size: 12px;
}
.procatlist { 
	margin-bottom: 3rem; 
}
.table-fixed-head { 
	width: calc(100% - 24px); 
}
.gridtable tbody{
  display: block;
  max-height: 195px;
  overflow-y: scroll;
}
.gridtable thead, 
.gridtable tbody tr {
  width: 100%;
  display: table;
  border-width: 0;
  table-layout: fixed;
}
.gridtable thead{
  width: calc(100% - 1.25em);
}
.gridtable thead{
  position: relative;
}
.gridtable thead th:last-child:after {
	top: 0;
	content: '';
	width: 1.5em;
	height: 100%;
	right: -1.25em;
	position: absolute;
	border-color: #ddd;
	border-style: solid;
	background-color: #f4f4f4;
	border-width: 1px 1px 1px 0;
}
.plan-container {
	display: flex;
	flex-flow: wrap;
}
.floorplan-thumnail {
	width: 200px;
	display: flex;
	margin: 0 15px 15px 0;
	flex-direction: column;
}
.floorplan-thumnail .img {
	display: flex;
	height: 150px;
	cursor: pointer;
	border: green solid;
	align-items: center;
	border-width: 3px 3px 0;
	justify-content: center;
}
.floorplan-thumnail .dropzone-style {
	padding: 10px;
	text-align: center;
}
.floorplan-thumnail p { 
	margin-bottom: 0;
}
.floorplan-thumnail img {
	max-width: 100%;
	max-height: 100%;
}
.floorplan-thumnail span {
  color: #fff;
  width: 100%;
  height: 45px;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  background: green;
  align-items: center;
  padding: 0px 60px 0px 5px;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}
.floorplan-thumnail span.only {
	padding: 0px 22px 0px 5px;
}
.floorplan-thumnail span span {
  display: flex;
  align-items: center;
  padding: 0px 5px 0px 0px;
  border-right: 1px solid #ffffff48;
}
.floorplan-thumnail .btn-primary {
  margin-top: 3px;
	font-size: 12px;
  border-radius: 0;
	padding: 3px 5px;
	font-weight: bold;
	border-color: #008040;
	background-color: #008040;
}
.excelView-btn.bottom {
  right: 35px;
  position: absolute;
}
.floorplan-thumnail span.only .excelView-btn.bottom {
	right: 1px;
}
.trash-btn.bottom {
	position: absolute;
	right: 10px;
}
.css-42igfv {
	padding: 10px;
	border-radius: 5px;
	background: rgba(0,0,0,0.05);
}
.css-nasma1 {
	margin: 0 auto 10px;
	display: block !important;
}
#pricing-contract .select-material {
	width: 100px;
	display: block;
	margin: 50px auto;
}
#pricing-contract .data-loading {
	position: static;
}
#pricing-contract .step .title {
	line-height: 38px;
}
.summary .step {
	padding: 20px;
	margin-bottom: 15px;
	background-color: #fbfbfb;
	border: #e4e4e4 solid 1px;
}
.kit-container { 
	min-height: 300px; 
}
#pricing-contract {
  margin: 20px;
  max-width: 100%;
}
#contract-confirmation .modal-body > div {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}
#contract-confirmation .modal-body > div .form-label {
	width: 30%;
}
#contract-confirmation .modal-body > div .form-control {
	width: 70%;
}
.order-detail { 
	padding: 2rem;
	height: 100%;
	position: relative;
}
/*.order-detail:after {
	top: 50%;
	width: 1px;
	left: 100%;
	content: '';
	height: 100%;
	position: absolute;
	background: #c6c7c8;
	transform: translateY(-50%);
}*/
.order-detail label { 
	font-weight: 600; 
}
.order-detail label.form-check-label { 
	font-weight: normal; 
}
.fixTableHead {
	overflow-y: auto;
	max-height: 418px;
}
.fixTableHead thead th {
	top: 0;
	position: sticky;
}
.fixTableHead table {    
	width: 100%;
	border-collapse: collapse;
}
.fixTableHead th {
	background: #ABDD93;
}
#orderplace .modal-content {
	border: none;
	background: transparent;
}
.fixTableHead tbody tr:last-child td {
	bottom: 0;
	position: sticky;
	background: #f2f2f2;
}
.measuremodal {
  min-height: calc(100vh - 75px);
}

@media (max-width: 1200px) {
	.main-header h2 { 
		padding-left: 60px; 
	}
	.asideRight { 
		width: 100%; 
	}
}
@media (max-width: 767px) {
	.nav-cards .icon { 
		height: 30px; 
	}
	.nav-cards h5 { 
		font-size: 14px; 
		white-space: nowrap; 
	}
	.nav-cards h1 { 
		font-size: 18px; 
	}
}
@media (max-width: 600px) {
	.col { 
		flex: 0 0 100%; 
	}
	.asideRight { 
		padding: 0 10px; 
	}
	.main-header { 
		margin: 0 -10px 15px; 
	}
}
.overlay-tooltip {
	z-index: 0;
}
.overlay-tooltip > .tooltip-inner {
	background-color: #FF0000;
}
.bs-tooltip-end .tooltip-arrow::before, 
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
	border-right-color: #FF0000;
}
.pricing-contract-table-row {
	background: #FF0000;
}
.tooltip-inner {
	font-size: 14px;
	max-width: 350px;
	line-height: 1.3;
  font-weight: bold;
	padding: 0.6rem 0.5rem;
}
span.pc-error {
  top: -13px;
  color: white;
  width: 20px;
  height: 20px;
  line-height: 1;
	font-size: 24px;
  margin-top: 0px;
  background: red;
  padding: 2px 5px;
  font-weight: bold;
  margin-left: -10px;
  position: relative;
  border-radius: 20px;
  display: inline-block;
  box-sizing: border-box;
}
.floorplan-thumnail .btn-primary:focus {
	background: #008040;
	border-color: #008040;
}
.button-change-group-vertical button {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
}
/*.jobChangeModal .jobChangeModal-content {
	height: 600px;
}*/
textarea#changeJobNotes {
    height: 100px;
}
button.react-datepicker__close-icon {
    height: 36px;
}
.react-datepicker-wrapper input[type="text"] {
	width: 100%;
}
.jobChangeModal .modal-header {
	display: none;
}
.jobChangeModal.reschedule-content .modal-header {
	display: block;
	position: absolute;
	right: 10px;
	top: 10px;
	border-bottom: 0px;
	padding: 0px;
	z-index: 1;
	width: 30px;
	height: 30px;
}
.reschedule-content .modal-header .btn-close {
	margin: 0;
}
.changeJob-addOn-delete {
	cursor: pointer;
}